export const fetchBookingsByStatus = (status) => {
  return `/booking/${status}`;
};

export const fetchPreferPartnerURL = (source_city, destination_city) => {
  return `/booking/get-top-route-vendors?source_city=${source_city}&destination_city=${destination_city}`;
};

export const fetchBookingDetailsURL = (booking_id, url) => {
  return `/booking/${url}/${booking_id}`;
};

export const reviveBookingURL = () => {
  return `/booking/revive-booking`;
};

export const deactivateBookingURL = () => {
  return `/booking/deactivate-booking`;
};

export const activateBookingURL = () => {
  return `/booking/activate-booking`;
};

export const rejectBookingURL = () => {
  return `/booking/reject-booking`;
};

export const assignBookingURL = () => {
  return `/booking/assign-booking`;
};

export const reassignBookingURL = () => {
  return `/booking/assign-booking`;
};

export const dockBookingURL = () => {
  return `/booking/dock-booking`;
};

export const fetchBookingByIdURL = () => {
  return '/booking/booking-details';
};

export const createBookingURL = () => {
  return '/booking/create-booking';
};

export const editBookingURL = () => {
  return '/booking/edit-booking';
};

export const acceptBookingURL = () => {
  return '/booking/accept-booking';
};

export const fetchCitiesByCustomerURL = (customer) => {
  return `/booking/cities/${customer}`;
};

export const fetchProcuredByURL = () => {
  return `/booking/load-procured-users`;
};

export const checkDockConditionURL = () => {
  return `/booking/check-dock-conditions`;
};

export const fetchTatAndDistanceURL = () => {
  return `/booking/autofill-tat-distance`;
};

export const fetchAvailableVehiclesURL = (search) => {
  return `/vehicle/available-vehicles${search ? '?' + search : ''}`;
};

export const downloadAvailableVehiclesReportURL = (search) => {
  return `/vehicle/available-vehicles-report${search ? '?' + search : ''}`;
};

export const downloadDockReportURL = () => {
  return `/booking/download-dock-report`;
};

export const downloadBookingReportURL = (status) => {
  return `/booking/download-booking-report/${status}`;
};

export const sendToLoadBoardURL = () => {
  return `/booking/edit-loads`;
};

export const fetchBookingBidsURL = (bookingId) => {
  return `/booking/pull-vendor-bids?booking_id=${bookingId}`;
};

export const closeBidURL = () => {
  return `/booking/close-loads`;
};

export const addBidDetailsURL = () => {
  return '/booking/create-bids';
};

export const updateBidURL = () => {
  return '/booking/update-bids';
};

export const fetchAvailableVehicleReportURL = (search) => {
  return `/vehicle/available-vehicle-days${search ? '?' + search : ''}`;
};

export const fetchBookingCommentsURL = (booking_id) => {
  return `/booking/booking-comment/${booking_id}`;
};

export const addBookingCommentsURL = (booking_id) => {
  return `booking/booking-comment/${booking_id}`;
};

export const addBookingsLocationURL = () => {
  return `/booking/booking-add-location-manual`;
};

export const chartDataURL = () => {
  return `/booking/chart-data`;
};
