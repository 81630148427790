import {doRequest} from '../../../helpers/network';
import {REQUEST_TYPE, BOOKING_STATUS} from '../../../constants';
import * as _ from 'lodash';
import {Bookings} from '../../../helpers/Urls';

import {
  REJECT_BOOKING_COMPLETED,
  ASSIGN_BOOKING_COMPLETED,
  DEACTIVATE_BOOKING_COMPLETED,
  REVIVE_BOOKING_COMPLETED,
  DOCK_BOOKING_COMPLETED,
} from '../../bookings';

export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const FETCH_BOOKINGS_COMPLETED = 'FETCH_BOOKINGS_COMPLETED';
export const FETCH_BOOKINGS_FAILED = 'FETCH_BOOKINGS_FAILED';

export const FETCH_PREFER_PARTNER = 'FETCH_PREFER_PARTNER';
export const FETCH_PREFER_PARTNER_COMPLETED = 'FETCH_PREFER_PARTNER_COMPLETED';
export const FETCH_PREFER_PARTNER_FAILED = 'FETCH_PREFER_PARTNER_FAILED';

export const SEARCH_IN_BOOKING_FALSE = 'SEARCH_IN_BOOKING_FALSE';
export const SEARCH_IN_BOOKING_TRUE = 'SEARCH_IN_BOOKING_TRUE';
export const APPLY_BOOKING_FILTER = 'APPLY_BOOKING_FILTER';
export const CLEAR_BOOKING_FILTER = 'CLEAR_BOOKING_FILTER';

export const RENDER_CHART_DATA = 'RENDER_CHART_DATA';
export const RENDER_CHART_DATA_COMPLETED = 'RENDER_CHART_DATA_COMPLETED';
export const RENDER_CHART_DATA_FAILED = 'RENDER_CHART_DATA_FAILED';

const INITIAL_STATE = {
  isFetching: false,
  hasError: false,
  error: '',
  new_bookings_ids: [],
  accepted_booking_ids: [],
  assigned_booking_ids: [],
  docked_booking_ids: [],
  rejected_booking_ids: [],
  scheduled_booking_ids: [],
  without_driver_booking_ids: [],
  isPreferPartnerLoading: false,
  preferPartnerData: [],
  chartDataObj: {},
  isChartDataFetching: false,
};

export const chartData = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: RENDER_CHART_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Bookings.chartDataURL(),
        data: {...requestData},
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: RENDER_CHART_DATA_COMPLETED, payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: RENDER_CHART_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: RENDER_CHART_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const fetchPreferPartner = (source, destination) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_PREFER_PARTNER});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchPreferPartnerURL(source, destination),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: FETCH_PREFER_PARTNER_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_PREFER_PARTNER_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_PREFER_PARTNER_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const searchInput = (searchInput = '') => (dispatch) => {
  if (!searchInput) {
    dispatch({
      type: SEARCH_IN_BOOKING_FALSE,
    });
    return;
  }
  dispatch({
    type: SEARCH_IN_BOOKING_TRUE,
    payload: searchInput,
  });
};

export const fetchBookingByStatus = (bookingStatus, search = '') => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_BOOKINGS});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Bookings.fetchBookingsByStatus(bookingStatus) + search,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: FETCH_BOOKINGS_COMPLETED,
          payload: {...data, bookingStatus},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_BOOKINGS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_BOOKINGS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const onBookingsFetchedSuccessfully = (state, payload) => {
  const {
    booking_ids = [],
    Counts = {},
    bookingStatus = '',
    filters = {},
  } = payload;
  if (bookingStatus === BOOKING_STATUS.NEW_REQUESTS) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      new_bookings_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.ACCEPTED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      accepted_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.ASSIGNED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      assigned_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.DOCKED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      docked_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.SCHEDULED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      scheduled_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.REJECTED) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      rejected_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  } else if (bookingStatus === BOOKING_STATUS.BOOKING_WITHOUT_DRIVER) {
    return {
      ...state,
      isFetching: false,
      hasError: false,
      without_driver_booking_ids: booking_ids,
      Counts: {
        ...state.Counts,
        ...Counts,
      },
      searching: '',
      filter: {
        ...filters,
      },
    };
  }
};

export const applyFilter = (filter = {}, tripStatus = '') => {
  return (dispatch) => {
    dispatch({
      type: APPLY_BOOKING_FILTER,
      payload: filter,
    });
  };
};

export const clearFilter = (filter = {}) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_BOOKING_FILTER,
    });
  };
};

const filterArray = (entry, array) => {
  const newArray = [...array];
  const index = newArray.indexOf(entry);
  if (index !== -1) {
    newArray.splice(index, 1);
  }
  return newArray;
};

const removeBookingId = (state, payload) => {
  const {booking_id = '', Counts = {}} = payload;
  const {
    new_bookings_ids = [],
    accepted_booking_ids = [],
    assigned_booking_ids = [],
    docked_booking_ids = [],
    rejected_booking_ids = [],
    scheduled_booking_ids = [],
    without_driver_booking_ids = [],
  } = state;
  const newBookingsArray = filterArray(booking_id, new_bookings_ids);
  const acceptedBookingsArray = filterArray(booking_id, accepted_booking_ids);
  const assignedBookingsArray = filterArray(booking_id, assigned_booking_ids);
  const dockedBookingsArray = filterArray(booking_id, docked_booking_ids);
  const rejectedBookingsArray = filterArray(booking_id, rejected_booking_ids);
  const scheduledBookingsArray = filterArray(booking_id, scheduled_booking_ids);
  const withpurDriverBookingsArray = filterArray(
    booking_id,
    without_driver_booking_ids,
  );
  return {
    ...state,
    new_bookings_ids: newBookingsArray,
    accepted_booking_ids: acceptedBookingsArray,
    assigned_booking_ids: assignedBookingsArray,
    docked_booking_ids: dockedBookingsArray,
    rejected_booking_ids: rejectedBookingsArray,
    scheduled_booking_ids: scheduledBookingsArray,
    without_driver_booking_ids: withpurDriverBookingsArray,
    Counts,
  };
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  switch (type) {
    case RENDER_CHART_DATA:
      return {...state, isChartDataFetching: true};
    case RENDER_CHART_DATA_COMPLETED:
      return {
        ...state,
        isChartDataFetching: false,
        chartDataObj: payload.data,
      };
    case RENDER_CHART_DATA_FAILED:
      return {...state, isChartDataFetching: false};

    case FETCH_BOOKINGS:
      return {
        ...state,
        isFetching: true,
        hasError: false,
        error: '',
      };
    case FETCH_BOOKINGS_COMPLETED:
      return onBookingsFetchedSuccessfully(state, payload);
    case FETCH_BOOKINGS_FAILED:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        new_bookings_ids: [],
        accepted_booking_ids: [],
        assigned_booking_ids: [],
        docked_booking_ids: [],
        rejected_booking_ids: [],
        scheduled_booking_ids: [],
        without_driver_booking_ids: [],
        error: payload.message,
      };
    case FETCH_PREFER_PARTNER:
      return {
        ...state,
        isPreferPartnerLoading: true,
      };
    case FETCH_PREFER_PARTNER_COMPLETED:
      return {
        ...state,
        isPreferPartnerLoading: false,
        preferPartnerData: payload,
      };
    case FETCH_PREFER_PARTNER_FAILED:
      return {
        ...state,
        isPreferPartnerLoading: false,
      };
    case SEARCH_IN_BOOKING_FALSE:
      return {
        ...state,
        searching: '',
        // filteredTrips: null,
      };
    case SEARCH_IN_BOOKING_TRUE:
      return {
        ...state,
        searching: payload,
        // filteredTrips: payload,
      };
    case APPLY_BOOKING_FILTER:
      // localStorage.setItem('trip-filter', JSON.stringify(payload));
      return {
        ...state,
        filter: payload,
      };
    case CLEAR_BOOKING_FILTER:
      return {
        ...state,
        filter: {},
      };
    case REJECT_BOOKING_COMPLETED:
    case ASSIGN_BOOKING_COMPLETED:
    // case DEACTIVATE_BOOKING_COMPLETED:
    case REVIVE_BOOKING_COMPLETED:
    case DOCK_BOOKING_COMPLETED:
      return removeBookingId(state, payload);
    default:
      return state;
  }
};
